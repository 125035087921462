// Generated by Framer (21ec140)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["IclE6PD6d", "wM6xycs3Z"];

const serializationHash = "framer-TyFtW"

const variantClassNames = {IclE6PD6d: "framer-v-48b30k", wM6xycs3Z: "framer-v-1eokh5a"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "IclE6PD6d", Variant: "wM6xycs3Z"}

const getProps = ({height, hover, id, width, ...props}) => { return {...props, bP8EpQvOa: hover ?? props.bP8EpQvOa, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "IclE6PD6d"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;hover?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, bP8EpQvOa, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "IclE6PD6d", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onMouseEnter1mbajit = activeVariantCallback(async (...args) => {
if (bP8EpQvOa) {
const res = await bP8EpQvOa(...args);
if (res === false) return false;
}
setVariant("wM6xycs3Z")
})

const onMouseLeave1lfev00 = activeVariantCallback(async (...args) => {
await delay(() => setVariant("IclE6PD6d"), 100)
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Image {...restProps} background={{alt: "", fit: "fill", intrinsicHeight: 550, intrinsicWidth: 850, pixelHeight: 1261, pixelWidth: 1280, src: "https://framerusercontent.com/images/T0sCNlAKVIBpINZgFOR8WfM8d0.jpeg"}} className={cx(serializationHash, ...sharedStyleClassNames, "framer-48b30k", className, classNames)} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"IclE6PD6d"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onMouseEnter={onMouseEnter1mbajit} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({wM6xycs3Z: {"data-framer-name": undefined, onMouseLeave: onMouseLeave1lfev00}}, baseVariant, gestureVariant)}/></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-TyFtW.framer-1jvhxmz, .framer-TyFtW .framer-1jvhxmz { display: block; }", ".framer-TyFtW.framer-48b30k { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: 439px; justify-content: center; overflow: hidden; padding: 0px; position: relative; width: 635px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-TyFtW.framer-48b30k { gap: 0px; } .framer-TyFtW.framer-48b30k > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-TyFtW.framer-48b30k > :first-child { margin-left: 0px; } .framer-TyFtW.framer-48b30k > :last-child { margin-right: 0px; } }", ".framer-TyFtW.framer-v-1eokh5a.framer-48b30k { aspect-ratio: 1.4464692482915718 / 1; height: var(--framer-aspect-ratio-supported, 487px); width: 704px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 439
 * @framerIntrinsicWidth 635
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"wM6xycs3Z":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"bP8EpQvOa":"hover"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerRRjhQTGgR: React.ComponentType<Props> = withCSS(Component, css, "framer-TyFtW") as typeof Component;
export default FramerRRjhQTGgR;

FramerRRjhQTGgR.displayName = "photo 2";

FramerRRjhQTGgR.defaultProps = {height: 439, width: 635};

addPropertyControls(FramerRRjhQTGgR, {variant: {options: ["IclE6PD6d", "wM6xycs3Z"], optionTitles: ["Variant 1", "Variant"], title: "Variant", type: ControlType.Enum}, bP8EpQvOa: {title: "Hover", type: ControlType.EventHandler}} as any)

addFonts(FramerRRjhQTGgR, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})